import React from "react"
import YouTube from "react-youtube"
import Link from "next/link"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileContract,
  faQuestionCircle,
  faInfoCircle,
  faLightbulbOn,
  faLocationDot,
  faClock
} from "@fortawesome/pro-duotone-svg-icons"

export default function Welcome() {
  return (
    <div className="min-h-0 flex flex-col">
      <div className="scrollbar px-3 pb-6 overflow-y-auto">
        <div className="pt-5 text-xl font-semibold">
          Welcome to the Options Backtester by ORATS!
        </div>
        <div className="mt-4 md:flex flex-row font-normal">
          <div className="mr-8 flex-none md:w-[32rem] lg:w-[44rem] w-full">
            <Video id="7H9QVYYp8l4" />
            <div className="hidden md:block mt-8 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faFileContract}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                Disclaimer
              </div>
              <div className="p-3">
                <p>
                  The opinions and ideas presented herein are for informational
                  and educational purposes only and should not be construed to
                  represent trading or investment advice tailored to your
                  investment objectives. You should not rely solely on any
                  content herein and we strongly encourage you to discuss any
                  trades or investments with your broker or investment adviser,
                  prior to execution. None of the information contained herein
                  constitutes a recommendation that any particular security,
                  portfolio, transaction, or investment strategy is suitable for
                  any specific person. Option trading and investing involves
                  risk and is not suitable for all investors.
                </p>
                <p className="mt-2">
                  All opinions are based upon information and systems considered
                  reliable, but we do not warrant the completeness or accuracy,
                  and such information should not be relied upon as such. We are
                  under no obligation to update or correct any information
                  herein. All statements and opinions are subject to change
                  without notice.
                </p>
                <p className="mt-2">
                  Past performance is not indicative of future results. We do
                  not, will not and cannot guarantee any specific outcome or
                  profit. All traders and investors must be aware of the real
                  risk of loss in following any strategy or investment discussed
                  herein.
                </p>
                <p className="mt-2">
                  Owners, employees, directors, shareholders, officers, agents
                  or representatives of ORATS may have interests or positions in
                  securities of any company profiled herein. Specifically, such
                  individuals or entities may buy or sell positions, and may or
                  may not follow the information provided herein. Some or all of
                  the positions may have been acquired prior to the publication
                  of such information, and such positions may increase or
                  decrease at any time. Any opinions expressed and/or
                  information are statements of judgment as of the date of
                  publication only.
                </p>
                <p className="mt-2">
                  Day trading, short term trading, options trading, and futures
                  trading are extremely risky undertakings. They generally are
                  not appropriate for someone with limited capital, little or no
                  trading experience, and/ or a low tolerance for risk. Never
                  execute a trade unless you can afford to and are prepared to
                  lose your entire investment. In addition, certain trades may
                  result in a loss greater than your entire investment. Always
                  perform your own due diligence and, as appropriate, make
                  informed decisions with the help of a licensed financial
                  professional.
                </p>
                <p className="mt-2">
                  Commissions, fees and other costs associated with investing or
                  trading may vary from broker to broker. All investors and
                  traders are advised to speak with their stock broker or
                  investment adviser about these costs. Be aware that certain
                  trades that may be profitable for some may not be profitable
                  for others, after taking into account these costs. In certain
                  markets, investors and traders may not always be able to buy
                  or sell a position at the price discussed, and consequently
                  not be able to take advantage of certain trades discussed
                  herein.
                </p>
                <p className="mt-2">
                  Be sure to read the OCCs Characteristics and Risks of
                  Standardized Options to learn more about options trading.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="mt-4 md:mt-0 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                What is it?
              </div>
              <div className="p-3">
                <p>
                  This is a tool for browsing and creating options backtests –
                  simulations of options trading strategies using historical
                  data. You can use this tool to see how different strategies
                  would have performed in the past.
                </p>
                <p className="mt-2">
                  To help you get started, we&apos;ve included over 180 million
                  backtests for more than 100 symbols, which you can browse and
                  filter in the{" "}
                  <Link
                    href="/all-backtests"
                    className="text-orange-600 underline cursor-pointer"
                  >
                    All Backtests
                  </Link>{" "}
                  tab. Once you find a backtest you like, you can click it to
                  pull up the backtest details, then save or edit it in the{" "}
                  <Link
                    href="/custom-backtests"
                    className="text-orange-600 underline cursor-pointer"
                  >
                    Custom Backtester
                  </Link>
                  .
                </p>
                <p className="mt-2">
                  The{" "}
                  <Link
                    href="/featured-backtests"
                    className="text-orange-600 underline cursor-pointer"
                  >
                    Featured Backtests
                  </Link>{" "}
                  tab shows you the top performing backtests (sorted by best
                  return on risk) whose entry triggers match the current
                  environment. For more information on backtesting, please visit
                  our{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: {
                            url: "https://orats.com/university/backtesting-methodology?utm_source=ib&utm_campaign=iframe"
                          }
                        },
                        "*"
                      )
                    }}
                  >
                    backtesting methodology
                  </span>{" "}
                  and related pages.
                </p>
              </div>
            </div>
            <div className="mt-4 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                Who is ORATS?
              </div>
              <div className="p-3">
                <p>
                  ORATS (Option Research & Technology Services) specializes in
                  options research, tools and data. We deliver our work via an
                  online platform of tools and APIs to professional traders and
                  investors. ORATS is an RIA (Registered Investment Advisor).
                  See our terms and RIA disclosers{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: {
                            url: "https://orats.com/terms-conditions?utm_source=ib&utm_campaign=iframe"
                          }
                        },
                        "*"
                      )
                    }}
                  >
                    here
                  </span>
                  .
                </p>
                <p className="mt-2">
                  ORATS was started in 2001 by Matt Amberson, a former market
                  maker on the floor of the CBOE. Matt is also the host of our{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: {
                            url: "https://hub.tradier.com/shows/driven-by-data/"
                          }
                        },
                        "*"
                      )
                    }}
                  >
                    weekly live webinar series
                  </span>
                  , where he shares options trading insights and shows the
                  latest tools from ORATS.
                </p>
                <p className="mt-2">
                  You can reach us at support@orats.com. To learn more about
                  ORATS, visit our{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: {
                            url: "https://orats.com/university?utm_source=ib&utm_campaign=iframe"
                          }
                        },
                        "*"
                      )
                    }}
                  >
                    free 15+ course University
                  </span>
                  , where we help traders like you navigate the markets more
                  effectively, gain an edge, and make money trading options. We
                  also have an active{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: {
                            url: "https://orats.com/blog?utm_source=ib&utm_campaign=iframe"
                          }
                        },
                        "*"
                      )
                    }}
                  >
                    blog
                  </span>{" "}
                  and{" "}
                  <span
                    className="text-orange-600 underline cursor-pointer"
                    onClick={() => {
                      window.top.postMessage(
                        {
                          action: "url_open",
                          payload: { url: "https://www.youtube.com/@orats" }
                        },
                        "*"
                      )
                    }}
                  >
                    Youtube channel
                  </span>
                  .
                </p>
              </div>
            </div>
            <div className="mt-4 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faLightbulbOn}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                Why should I backtest?
              </div>
              <div className="p-3">
                <p>
                  Backtesting is a powerful tool for you to test a strategy
                  before risking real money. While historical performance is not
                  an indicator of future results, backtesting can reveal
                  valuable insights about a strategy&apos;s performance in
                  different market conditions.
                </p>
                <p className="mt-2">
                  For example, you can test how a long straddle would have
                  performed when the VIX was above 20, or how a short put would
                  have performed when the stock was in a bullish trend.
                </p>
              </div>
            </div>
            <div className="mt-4 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                Where do I backtest?
              </div>
              <div className="p-3">
                <p>
                  You can either browse pre-canned backtests in the{" "}
                  <Link
                    href="/all-backtests"
                    className="text-orange-600 underline cursor-pointer"
                  >
                    All Backtests
                  </Link>{" "}
                  tab or create your own backtests in the{" "}
                  <Link
                    href="/custom-backtests"
                    className="text-orange-600 underline cursor-pointer"
                  >
                    Custom Backtester
                  </Link>{" "}
                  tab. The same symbols and strategies are available in both.
                </p>
              </div>
            </div>
            <div className="mt-4 border border-slate-200 dark:border-zinc-500 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row items-center border-b border-slate-200 p-3 bg-slate-50 dark:bg-zinc-800 dark:border-zinc-500">
                <FontAwesomeIcon
                  icon={faClock}
                  className="mr-3 text-blue-700 dark:text-blue-400"
                />{" "}
                When does my backtest run?
              </div>
              <div className="p-3">
                <p>
                  Every backtest is run using end-of-day options data gathered
                  14 minutes before the close. Each backtest starts from 2007
                  (or sooner for recent symbols) and runs to the present day.
                </p>
              </div>
            </div>
            <div className="block md:hidden mt-4 border border-slate-200 rounded-md overflow-hidden">
              <div className="text-lg font-semibold flex flex-row border-b border-slate-200 p-3 bg-slate-50">
                Disclaimer
              </div>
              <div className="p-3">
                <p>
                  The opinions and ideas presented herein are for informational
                  and educational purposes only and should not be construed to
                  represent trading or investment advice tailored to your
                  investment objectives. You should not rely solely on any
                  content herein and we strongly encourage you to discuss any
                  trades or investments with your broker or investment adviser,
                  prior to execution. None of the information contained herein
                  constitutes a recommendation that any particular security,
                  portfolio, transaction, or investment strategy is suitable for
                  any specific person. Option trading and investing involves
                  risk and is not suitable for all investors.
                </p>
                <p className="mt-2">
                  All opinions are based upon information and systems considered
                  reliable, but we do not warrant the completeness or accuracy,
                  and such information should not be relied upon as such. We are
                  under no obligation to update or correct any information
                  herein. All statements and opinions are subject to change
                  without notice.
                </p>
                <p className="mt-2">
                  Past performance is not indicative of future results. We do
                  not, will not and cannot guarantee any specific outcome or
                  profit. All traders and investors must be aware of the real
                  risk of loss in following any strategy or investment discussed
                  herein.
                </p>
                <p className="mt-2">
                  Owners, employees, directors, shareholders, officers, agents
                  or representatives of ORATS may have interests or positions in
                  securities of any company profiled herein. Specifically, such
                  individuals or entities may buy or sell positions, and may or
                  may not follow the information provided herein. Some or all of
                  the positions may have been acquired prior to the publication
                  of such information, and such positions may increase or
                  decrease at any time. Any opinions expressed and/or
                  information are statements of judgment as of the date of
                  publication only.
                </p>
                <p className="mt-2">
                  Day trading, short term trading, options trading, and futures
                  trading are extremely risky undertakings. They generally are
                  not appropriate for someone with limited capital, little or no
                  trading experience, and/ or a low tolerance for risk. Never
                  execute a trade unless you can afford to and are prepared to
                  lose your entire investment. In addition, certain trades may
                  result in a loss greater than your entire investment. Always
                  perform your own due diligence and, as appropriate, make
                  informed decisions with the help of a licensed financial
                  professional.
                </p>
                <p className="mt-2">
                  Commissions, fees and other costs associated with investing or
                  trading may vary from broker to broker. All investors and
                  traders are advised to speak with their stock broker or
                  investment adviser about these costs. Be aware that certain
                  trades that may be profitable for some may not be profitable
                  for others, after taking into account these costs. In certain
                  markets, investors and traders may not always be able to buy
                  or sell a position at the price discussed, and consequently
                  not be able to take advantage of certain trades discussed
                  herein.
                </p>
                <p className="mt-2">
                  Be sure to read the OCCs Characteristics and Risks of
                  Standardized Options to learn more about options trading.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Video({ id }) {
  return (
    <YouTube
      videoId={id}
      opts={{
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 0
        }
      }}
      style={{
        width: "100%",
        aspectRatio: "16/9"
      }}
    />
  )
}
