import cx from "classnames"

import * as TooltipPrimitive from "@radix-ui/react-tooltip"

const Tooltip = ({
  children,
  content,
  open,
  defaultOpen,
  onOpenChange,
  className,
  side = "bottom",
  usePortal = false
}) => {
  const _content = (
    <TooltipPrimitive.Content
      side={side}
      sideOffset={4}
      className={cx(
        "radix-side-bottom:animate-slide-up-fade",
        "radix-side-top:animate-slide-down-fade",
        "radix-side-right:animate-slide-left-fade",
        "radix-side-bottom:animate-slide-up-fade",
        "radix-side-left:animate-slide-right-fade",
        className
      )}
    >
      <TooltipPrimitive.Arrow className="fill-current" />
      {content}
    </TooltipPrimitive.Content>
  )

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root
        delayDuration={100}
        open={open}
        defaultOpen={defaultOpen}
        onOpenChange={onOpenChange}
      >
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        {usePortal ? (
          <TooltipPrimitive.Portal
            container={document.getElementById("radixPortal")}
          >
            {_content}
          </TooltipPrimitive.Portal>
        ) : (
          _content
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

export default Tooltip
